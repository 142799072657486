// font-family: 'Poppins', sans-serif;
body {
    font-family: 'Poppins', sans-serif;
}

.navbar {
    position: absolute ;
    top: 0;
    left: 0;
    margin: 0 auto;
    width: 100%;
    z-index: 99;
    height: 120px;
    box-shadow: 6px 8px 15px rgba(59, 61, 191, 0.05);
    backdrop-filter: blur(30px);


    .navbar-brand {
        color: #222222;
        font-size: 26px;
        font-weight: 600;

        &:hover{
            color: #222222 !important;
        }

        img {
            margin-right: 10px;
        }

        &:hover {
            color: #222222;
        }
    }

    .nav-link {
        color: #222222 !important;
        margin: 0px 16px;
        line-height: 60px;
        font-weight: 500;
        font-size: 18px;
        padding: 0 !important;
    }

    .contact-btn {
        color: #fff !important;
        height: 60px;
        width: 150px;
        font-size: 20px;
        line-height: 60px;
        text-align: center;
        font-weight: 600;
        border-radius: 15px;
        transition: all linear .3s;
        background: -webkit-linear-gradient(-65deg, #c165dd 0, #1153fc 100%);

        &::after {
            height: 0;
        }

       
    }
}

.navbar-expand-lg .navbar-nav {
    padding: 15px 20px;
}


