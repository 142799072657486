#footer {
    padding: 100px 0px 50px;
    background-image: url(../images/c-feature.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    h2 {
        font-size: 42px;
        line-height: 50px;
        font-weight: 700;
        color: #222222;
        text-align: center;
        padding-bottom: 30px;

        span {
            color: #3b368c;
        }
    }
    ul{
        padding-left: 0px;
    }

    .footer-wrapper {

        li {
            display: inline-block;

            padding: 0px 25px;

            .footer-link {
                display: inline-block;
                background-color: white;
                width: 90px;
                height: 90px;
                line-height: 90px;
                margin: 5px;
                position: relative;
                overflow: hidden;
                text-align: center;
                /* comment for see before */
                border-radius: 30%;
                box-shadow: 0 5px 15px -5px #00000022;
                cursor: pointer;

                svg {
                    font-size: 38px;
                    vertical-align: middle;
                }

                &::before {
                    content: '';
                    position: absolute;
                    width: 120%;
                    height: 120%;
                    background-color: red;
                    transform: rotate(45deg);
                    top: 90%;
                    left: -110%;
                }

                &:focus {
                    &:before {
                        top: -10%;
                        left: -10%;
                    }
                }

                &:hover {
                    &:before {
                        top: -10%;
                        left: -10%;
                    }
                }

                &:hover {
                    svg {
                        color: #fff;
                        transform: scale(1.3);
                    }
                }

                transition: all 0.35s;
                transition-timing-function: cubic-bezier(0.31, -0.105, 0.43, 1.59);

                &:before {
                    transition: all 0.35s;
                    transition-timing-function: cubic-bezier(0.31, -0.105, 0.43, 1.59);
                }

                svg {
                    transition: all 0.35s;
                    transition-timing-function: cubic-bezier(0.31, -0.105, 0.43, 1.59);
                }
            }

            .flink {
                color: #3B5998;

                &::before {
                    background-color: #3B5998;
                }
            }

            .tlink {
                color: #3CF;

                &::before {
                    background-color: #3CF;
                }
            }

            .telelink {
                color: #2DA5D9;

                &::before {
                    background-color: #2DA5D9;
                }
            }

            .dislink {
                color: #5560E9;

                &::before {
                    background-color: #5560E9;
                }
            }

            .youlink {
                color: #FF0000;

                &::before {
                    background-color: #FF0000;
                }
            }




            // a {
            //     text-decoration: none;
            //     color: #7c8cce;
            //     transition: all linear .3s;


            //     &:hover {
            //         color: #0b0744;
            //     }
            // }

            // svg {
            //     margin-right: 10px;
            // }
        }
    }

    p {
        color: #a0a0a0;
        padding-top: 80px;
        text-align: center;

        a {
            text-decoration: none;
            color: #a0a0a0;
            transition: all linear .3s;


            &:hover {
                color: #0b0744;
            }
        }
    }
}