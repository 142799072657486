#cards {
    padding: 100px 0;
    background-image: url(../images/to-access-bg.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    .card-header {
        border-bottom: none;
        background-color: transparent;

        h2 {
            font-size: 42px;
            line-height: 50px;
            font-weight: 700;
            color: #222222;

            span {
                color: #3b368c;
            }
        }

        p {
            font-size: 18px;
            line-height: 24px;
            color: rgb(24, 25, 26);
            padding: 10px 0px 60px;
        }
    }

    .card-box {
        text-align: center;
        padding: 15px 10px;
        border: 1px solid transparent;
        border-radius: 5px;
        margin-bottom: 30px;
        transition: all linear .3s;
        cursor: pointer;
        background: rgba(255, 255, 255, 0.25);
        box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.06);
        backdrop-filter: blur(2px);
        -webkit-backdrop-filter: blur(4px);
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 0.18);

        &:hover {
            border: 1px solid #264653;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        }

        .card-title {
            font-weight: 600;
            font-size: 28px;
            line-height: 42px;
            color: #333333;
        }

        .img-box {
            height: 120px;
            width: 120px;
            margin-bottom: 20px;

            img {
                width: 100%;
            }
        }

        p {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #333333;
            padding: 20px 0px 10px;
        }

        ul {
            padding: 0;
            margin: 0;

            li {
                list-style: none;
                margin-bottom: 35px;
            }
        }

        .btn-one {
            border-radius: 10px;
            padding: 12px 72px;
            color: white;
            font-weight: 600;
            font-size: 18px;
            cursor: pointer;
            text-decoration: none;
            transition: all linear .3s;
            background: -webkit-linear-gradient(-65deg, #c165dd 0, #1153fc 100%);
            border: 2ps solid tra;


            &:hover {
                box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
            }
        }

        .btn-two {
            border-radius: 10px;
            padding: 12px 70px;
            color: #484e50;
            font-weight: 600;
            font-size: 18px;
            cursor: pointer;
            text-decoration: none;
            transition: all linear .3s;
            border: 2px solid #c165dd;

            &:hover {
                box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
            }

        }
    }
}