#blog {
    padding: 150px 0;
    background-image: url(../images/video-bg.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;

    img {
        width: 100%;
        border-radius: 5px;
    }

    h2 {
        font-size: 52px;
        line-height: 1.22em;
        font-weight: 700;
        color: #484e50;

        span {
            color: #3b368c;
        }
    }

    p {
        font-size: 18px;
        line-height: 24px;
        color: rgb(24, 25, 26);
        padding: 10px 0px 20px;
    }

    .carousel-item {
        z-index: 9;
    }

    .carousel-indicators {
        bottom: -100px;
    }

    .carousel-indicators [data-bs-target] {
        height: 5px;
        background-color: #6c63ff;
    }



    .carousel-control-prev {
        background: -webkit-linear-gradient(16deg, #e1a1ed 0, #ac7df1 35%, #7658f4 100%);
        height: 50px;
        width: 50px;
        border-radius: 15px;
        top: 50%;
        left: -40px;
        z-index: 999;
    }

    .carousel-control-next {
        background: -webkit-linear-gradient(16deg, #e1a1ed 0, #ac7df1 35%, #7658f4 100%);
        height: 50px;
        width: 50px;
        border-radius: 15px;
        top: 50%;
        right: -40px;
        z-index: 999;
    }
}