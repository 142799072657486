#offer {
    background-image: url(../images/banner-bg-2.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 120px 0;
    position: relative;

    &:after {
        content: "";
        background: url(../images/overly-img.png) no-repeat center;
        background-size: cover;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
    }

    .carousel-item {
        z-index: 9;
    }

    .img-box {
        img {
            width: 90%;
        }
    }

    h5 {
        font-size: 18px;
        line-height: 18px;
        color: #3b368c;
        text-transform: uppercase;
        font-weight: normal;
        margin-bottom: 24px;
        letter-spacing: 4.5px;
    }

    h2 {
        line-height: 62px;
        margin-bottom: 18px;
        font-size: 42px;
        font-weight: 700;
        color: #222222;
    }

    p {
        font-size: 18px;
        line-height: 32px;
        color: #222222;
        padding: 0px 0px 20px;
    }

    .know-btn {
        border-radius: 40px;
        padding: 15px 50px;
        font-weight: 600;
        font-size: 20px;
        border: none;
        cursor: pointer;
        transition: all linear .2s;
        color: white;
        background: -webkit-linear-gradient(-65deg, #c165dd 0, #1153fc 100%);
        position: absolute;
        text-decoration: none;

        &:hover {
            box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.404) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
        }
    }

    .carousel-indicators {
        bottom: -70px;
    }

    .carousel-indicators [data-bs-target] {
        height: 5px;
        background-color: #6c63ff;
    }

    .carousel-control-next {
        right: -100px;
    }

    .carousel-control-prev {
        left: -100px;
    }
}